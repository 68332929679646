<template>
    <div class="list-view">
        <page-title>
<!--            <el-button-->
<!--                @click="addRow"-->
<!--                type="primary"-->
<!--                icon="el-icon-plus"-->
<!--                :disabled="fetchingData || downloading"-->
<!--                class="filter-item"-->
<!--            >-->
<!--                新增-->
<!--            </el-button>-->
            <el-button
                @click="download"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                导出
            </el-button>
        </page-title>
        <div class="filters-container">
            <el-input placeholder="申购活动id" v-model="activeId"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
            <el-input placeholder="用户ID" v-model="userId"
                      clearable
                      class="filter-item search"
                      @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
            <el-input placeholder="手机号" v-model="phone"
                      clearable
                      class="filter-item search"
                      @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
            <el-input placeholder="抽签码" v-model="lottyCode"
                      clearable
                      class="filter-item search"
                      @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
            <el-select v-model="lottyStatus" @change="initData" placeholder="中签状态">
                <el-option :value="''" label="全部"></el-option>
                <el-option :value="'0'" label="已申购"></el-option>
                <el-option :value="'1'" label="已中签"></el-option>
                <el-option :value="'2'" label="未中签"></el-option>
            </el-select>
            <created-at-picker v-model="createdAt" @input="initData" name="创建"></created-at-picker>
            <el-input
                placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
            v-loading="fetchingData"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="activeId" label="活动ID"> </el-table-column>
            <el-table-column prop="name" label="活动名称" width="120"> </el-table-column>
            <el-table-column prop="collectionName" label="藏品名称"> </el-table-column>
            <el-table-column prop="collectionId" label="申购藏品ID" width="120"> </el-table-column>
<!--            <el-table-column prop="targetCollectionIdList" label="申购藏品ID">-->
<!--                <template slot-scope="{ row }">-->
<!--                    <span v-for="(item, index) in row.targetCollectionIdList" :key="index">{{ item }}，</span>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="activeStartTime" label="活动开始时间" width="150"></el-table-column>
            <el-table-column prop="activeEndTime" label="活动结束时间" width="150"></el-table-column>
            <el-table-column prop="userId" label="申购用户ID"></el-table-column>
            <el-table-column prop="phone" label="申购用户手机号" width="120"></el-table-column>
            <el-table-column prop="lottyCode" label="抽奖码" width="120"></el-table-column>
            <el-table-column prop="lottyStatus" label="抽奖状态" width="120">
                <template slot-scope="{ row }">
                    <span v-if="row.lottyStatus == 0">已申购</span>
                    <span v-if="row.lottyStatus == 1" style="color: red">已中签</span>
                    <span v-if="row.lottyStatus == 2">未中签</span>
                </template>
            </el-table-column>
            <el-table-column prop="createdAt" label="创建时间" width="150"></el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="150">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
<!--                    <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'ActivityDrawLotsLogList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            activeId: '',   // 搜索字段 活动id
            lottyStatus: '',    // 搜索字段 中奖状态
            userId: '',
            phone: '',
            lottyCode: '',
            search: '', // 搜索字段
            url: '/activityDrawLotsLog/all', // 列表接口
            downloading: false,
            createdAt: ''
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return {
                search: this.search,
                query: {
                    del: false,
                    activeId: this.activeId,
                    lottyStatus: this.lottyStatus,
                    createdAt: this.createdAt,
                    lottyCode: this.lottyCode,
                    userId: this.userId,
                    phone: this.phone
                }
            };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        // 新增
        addRow() {
            this.$router.push({
                path: '/activityDrawLotsLogEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        // 编辑
        editRow(row) {
            this.$router.push({
                path: '/activityDrawLotsLogEdit',
                query: {
                    id: row.id
                }
            });
        },
        // 导出
        download() {
            this.downloading = true;
            const params = {
                size: 10000,
                search: this.search,
                query: {
                    createdAt: this.createdAt,
                }
            };
            this.$axios
                .post('/activityDrawLotsLog/excel', params, {
                    responseType: 'blob'
                })
                .then(res => {
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', '申购记录.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        // 列表 - 删除
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activityDrawLotsLog/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
