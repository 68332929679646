<template>
  <div class="list-view">
    <page-title>
      <el-button
        @click="addRow"
        type="primary"
        icon="el-icon-plus"
        :disabled="fetchingData || downloading"
        class="filter-item"
      >
        新增
      </el-button>
      <!-- <el-button
        @click="download"
        icon="el-icon-upload2"
        :loading="downloading"
        :disabled="fetchingData"
        class="filter-item"
      >
        导出
      </el-button> -->
    </page-title>
    <div class="filters-container">
      <created-at-picker
        v-model="createdAt"
        @input="initData"
        name="创建"
      ></created-at-picker>
      <el-input
        placeholder="搜索..."
        v-model="search"
        clearable
        class="filter-item search"
        @keyup.enter.native="initData"
      >
        <el-button
          @click="initData"
          slot="append"
          icon="el-icon-search"
        > </el-button>
      </el-input>
    </div>
    <el-table
      :data="tableData"
      row-key="id"
      ref="table"
      header-row-class-name="table-header-row"
      header-cell-class-name="table-header-cell"
      row-class-name="table-row"
      cell-class-name="table-cell"
      :height="tableHeight"
      v-loading="fetchingData"
    >
      <el-table-column
        v-if="multipleMode"
        align="center"
        type="selection"
        width="50"
      > </el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        width="100"
      > </el-table-column>
      <el-table-column
        prop="name"
        label="活动名称"
      > </el-table-column>

      <el-table-column
        prop="targetCollectionIdList"
        label="惊喜盒子藏品ID"
      >
        <template slot-scope="{ row }">
          <span
            v-for="(item, index) in row.properties"
            :key="index"
          >{{ item.collectionId }}，</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="grantCount"
        label="已发盒子数量"
      > </el-table-column>
      <el-table-column
        prop="boxSum"
        label="盒子总数"
      > </el-table-column>

      <el-table-column
        prop="createdAt"
        label="创建时间"
      ></el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fixed="right"
        width="150"
      >
        <template slot-scope="{ row }">
          <el-button
            @click="editRow(row)"
            type="primary"
            size="mini"
            plain
          >详情</el-button>
          <el-button
            @click="deleteRow(row)"
            type="danger"
            size="mini"
            plain
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper">
      <el-pagination
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalElements"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'SurpriseBoxList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '', // 搜索字段
            url: '/activitySurpriseBox/all', // 列表接口
            downloading: false,
            createdAt: ''
        };
    },
    filters: {
        // getState: ()={
        // }
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return { search: this.search, query: { del: false, createdAt: this.createdAt } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        // 新增
        addRow() {
            this.$router.push({
                path: '/surpriseBoxEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        // 编辑
        editRow(row) {
            this.$router.push({
                path: '/surpriseBoxEdit',
                query: {
                    id: row.id
                }
            });
        },
        // 导出
        download() {
            this.downloading = true;
            const params = {
                size: 10000,
                search: this.search,
                query: {
                    createdAt: this.createdAt,
                    projectId: this.$store.state.projectId
                }
            };
            this.$axios
                .post('/activitySurpriseBox/excel', params, {
                    responseType: 'blob'
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', '优先购.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        // 列表 - 删除
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activitySurpriseBox/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
