<template>
  <div class="list-view">
    <page-title>

      <el-button @click="download"
                 icon="el-icon-upload2"
                 :loading="downloading"
                 :disabled="fetchingData"
                 class="filter-item">
        导出
      </el-button>
    </page-title>
    <div class="filters-container">
      <el-form :model="formData"
               :inline="true">
        <el-form-item label="创建时间">
          <created-at-picker v-model="formData.createdAt"
                             @input="initData"
                             name="创建"></created-at-picker>
        </el-form-item>
        <el-form-item label="盒子状态">
          <el-select v-model="formData.status"
                     placeholder="筛选状态"
                     clearable
                     @change="initData"
                     style="margin-right: 10px">
            <el-option v-for="item in boxStatusMap"
                       :key="item.key"
                       :value="item.key"
                       :label="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- <created-at-picker v-model="createdAt"
                         @input="initData"
                         name="创建"></created-at-picker> -->
      <el-input placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData">
        <el-button @click="initData"
                   slot="append"
                   icon="el-icon-search"> </el-button>
      </el-input>
    </div>
    <el-table :data="tableData"
              row-key="id"
              ref="table"
              header-row-class-name="table-header-row"
              header-cell-class-name="table-header-cell"
              row-class-name="table-row"
              cell-class-name="table-cell"
              :height="tableHeight"
              v-loading="fetchingData">
      <el-table-column v-if="multipleMode"
                       align="center"
                       type="selection"
                       width="50"> </el-table-column>
      <el-table-column prop="id"
                       label="ID"
                       width="100"> </el-table-column>
      <el-table-column prop="name"
                       label="活动名称">

      </el-table-column>

      <el-table-column prop="collectionId"
                       label="惊喜盒子藏品ID">
        <!-- <template slot-scope="{ row }">
          {{scope}}
          <span
            v-for="(item, index) in row"
            :key="index"
          >{{ item.collectionId }}，</span>
        </template> -->
      </el-table-column>
      <el-table-column prop="user_id"
                       label="用户ID"
                       :formatter="(row) => (row.user_id|| row.userId)"> </el-table-column>
      <el-table-column prop="phone"
                       label="手机号"> </el-table-column>
      <el-table-column v-if="formData.status != ''"
                       prop="disabled"
                       label="是否打开"
                       :formatter="(row) => (row.disabled ? '未打开' : '已打开')"> </el-table-column>
      <el-table-column v-else
                       prop="
                       shipments"
                       label="是否发货"
                       :formatter="(row) => (row.shipments ? '已发货' : '未发货')"> </el-table-column>
      <el-table-column prop="remarks"
                       label="备注"> </el-table-column>
      <el-table-column prop="createdAt"
                       label="创建时间"></el-table-column>
      <!-- <el-table-column
        prop="picUrl"
        label="实物图片"
      >
        <template slot-scope="{ row }">
          <el-avatar
            v-if="row.picUrl"
            shape="square"
            :size="size"
            :src="row.picUrl"
          ></el-avatar>
        </template>
      </el-table-column> -->
      <el-table-column prop="picUrl"
                       v-if="formData.status == ''"
                       label="实物图片"
                       width="90"
                       align="center">
        <template slot-scope="{ row }">
          <el-image v-if="row.picUrl"
                    style="width: 40px; height: 40px"
                    :src="row.picUrl"
                    fit="cover"
                    :preview-src-list="[row.picUrl]"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       v-if="formData.status == ''"
                       align="center"
                       fixed="right"
                       width="150">
        <template slot-scope="{ row }">
          <el-button @click="shipments(row)"
                     type="primary"
                     size="mini"
                     plain>发货</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper">
      <el-pagination background
                     @size-change="onSizeChange"
                     @current-change="onCurrentChange"
                     :current-page="page"
                     :page-sizes="[10, 20, 30, 40, 50]"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="totalElements">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'SupBoxList',
    mixins: [pageableTable],
    data() {
        return {
            formData: {
                status: ''
            },
            multipleMode: false,
            search: '', // 搜索字段
            url: `/surpriseBoxLog/all`, // 列表接口
            downloading: false,
            createdAt: '',
            boxStatusMap: [
                {
                    value: '全部',
                    key: ''
                },
                {
                    value: '打开',
                    key: 0
                },
                {
                    value: '未打开',
                    key: 1
                }
            ]
        };
    },
    filters: {
        // getState: ()={
        // }
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return { search: this.search, query: { type: '1', del: false, ...this.formData } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        // 导出
        download() {
            this.downloading = true;
            const params = {
                size: 10000,
                search: this.search,
                query: {
                    ...this.formData,
                    projectId: this.$store.state.projectId
                }
            };
            const src = this.formData.status == '' ? '/surpriseBoxLog/excel' : '/userWareHouse/excel';
            this.$axios
                .post(src, params, {
                    responseType: 'blob'
                })
                .then(res => {
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', '惊喜盒子名单.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        //
        shipments(row) {
            // this.$alert('确定发货吗？', '提示', { type: 'warning' })
            //     .then(() => {
            //         return this.$http.post(`/surpriseBoxLog/shipments/${row.id}`);
            //     })
            //     .then(() => {
            //         this.$message.success('发货成功');
            //         this.getData();
            //     })
            //     .catch(e => {
            //         if (e !== 'cancel') {
            //             this.$message.error(e.error);
            //         }
            //     });
            const params = {
                ...row,
                shipments: true
            };
            this.$http
                .post('/surpriseBoxLog/save', params, { body: 'json' })
                .then(res => {
                    this.$message.success('发货成功');
                    this.getData();
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        }
    },
    watch: {
        'formData.status'(newVal, oldVal) {
            if (newVal != '') {
                this.url = '/userWareHouse/all';
                this.initData();
            }
        }
    }
};
</script>
<style lang="less" scoped></style>
