<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="160px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >

                    <el-form-item label="活动ID">
                        <el-input v-model="formData.activeId"></el-input>
                    </el-form-item>
                    <el-form-item label="活动名称">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="collectionId" label="申购藏品">
                        <el-form-item prop="collectionId">
                            <collection-search @select="onSelectTarget" v-model="formData.collectionId" :projectId="formData.projectId">
                            </collection-search>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item prop="activeStartTime" label="活动开始时间" :rules="{ required: true, message: '请选择活动开始时间' }">
                        <el-date-picker v-model="formData.activeStartTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="activeEndTime" label="活动结束时间" :rules="{ required: true, message: '请选择活动结束时间' }">
                        <el-date-picker v-model="formData.activeEndTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item prop="userId" label="申购用户ID">
                        <el-input v-model="formData.userId"></el-input>
                    </el-form-item>
                    <el-form-item prop="phone" label="申购用户手机号">
                        <el-input v-model="formData.phone"></el-input>
                    </el-form-item>
                    <el-form-item prop="lottyCode" label="抽奖码">
                        <el-input v-model="formData.lottyCode"></el-input>
                    </el-form-item>

                    <el-form-item label="抽奖状态" prop="lottyStatus">
                        <el-select v-model="formData.lottyStatus">
                            <el-option :value="'0'" label="已申购"></el-option>
                            <el-option :value="'1'" label="已中签"></el-option>
                            <el-option :value="'2'" label="未中签"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import RichText from '../components/RichText.vue';
import SingleUpload from '../components/SingleUpload.vue';
export default {
    components: { RichText, SingleUpload },
    name: 'ActivityDrawLotsLogEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('/activityDrawLotsLog/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {
                projectId: this.$store.state.projectId,
            },
            rules: {
            }
        };
    },

    computed: {
        canEdit() {
            return !!!this.$route.query.id;
        }
    },
    methods: {
        onSelectTarget(e) {
            console.log(e);
            if (e) {
                this.$set(this.formData, 'targetCollectionName', e.name);
            }
        },
        // 保存验证
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        // 新增 / 编辑
        submit() {
            let data = { ...this.formData };
            console.log(data);

            this.saving = true;
            this.$http
                .post('/activityDrawLotsLog/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activityDrawLotsLog/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
