<template>
    <div class="list-view">
        <page-title>
            <el-button
                @click="addRow"
                type="primary"
                icon="el-icon-plus"
                :disabled="fetchingData || downloading"
                class="filter-item"
            >
                新增
            </el-button>
            <el-button
                @click="download"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                导出
            </el-button>
        </page-title>
        <div class="filters-container">
            <created-at-picker v-model="createdAt" @input="initData" name="创建"></created-at-picker>
            <el-input
                placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
            v-loading="fetchingData"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="name" label="活动名称" width="120"> </el-table-column>
            <el-table-column prop="collectionName" label="藏品名称"> </el-table-column>
            <el-table-column prop="collectionId" label="藏品ID"> </el-table-column>
<!--            <el-table-column prop="targetCollectionIdList" label="申购藏品ID">-->
<!--                <template slot-scope="{ row }">-->
<!--                    <span v-for="(item, index) in row.targetCollectionIdList" :key="index">{{ item }}，</span>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="targetCollectionId" label="申购藏品ID" width="120"> </el-table-column>
            <el-table-column prop="targetCollectionName" label="申购藏品名称" width="120"> </el-table-column>
            <el-table-column prop="advancePurchaseTime" label="提前时间（h）"> </el-table-column>
            <el-table-column prop="advancePurchaseCount" label="购买次数"> </el-table-column>
            <el-table-column prop="itemId" label="凭证类型">
                <template slot-scope="{ row }">
                    <span v-if="row.itemId == 0">永久性优先购凭证</span>
                    <span v-if="row.itemId == 1">消耗性全局优先购凭证</span>
                    <span v-if="row.itemId == 2">消耗性指定藏品优先购凭证</span>
                </template>
            </el-table-column>

            <el-table-column prop="activeStartTime" label="活动开始时间" width="150"></el-table-column>
            <el-table-column prop="activeEndTime" label="活动结束时间" width="150"></el-table-column>
            <el-table-column prop="activeTicketNum" label="活动总签数"></el-table-column>
            <el-table-column prop="applicationLottyTicketNum" label="总抽签数（展示用）" width="120"></el-table-column>
            <el-table-column prop="lottyTicketNum" label="总参与抽签数/实际签数" width="120"></el-table-column>
            <el-table-column prop="participateNum" label="已参与人数"></el-table-column>
            <el-table-column prop="lottyTime" label="抽签时间" width="150"></el-table-column>
            <el-table-column prop="winners" label="中签人信息"></el-table-column>
            <el-table-column prop="activeStatus" label="活动是否有效" width="120">
                <template slot-scope="{ row }">
                    <span v-if="row.activeStatus == 0">删除</span>
                    <span v-if="row.activeStatus == 1">有效</span>
                    <span v-if="row.activeStatus == 9" style="color: red">已开奖</span>
                </template>
            </el-table-column>
            <el-table-column prop="lottyGenConf" label="抽奖码生成格式配置" width="150"></el-table-column>
            <el-table-column prop="stackable" label="可申购次数是否可叠加" width="150">
                <template slot-scope="{ row }">
                    <span v-if="row.stackable">可叠加</span>
                    <span v-if="!row.stackable">不可叠加</span>
                </template>
            </el-table-column>
            <el-table-column prop="finishShowTime" label="活动结束公示时间" width="150"></el-table-column>
            <el-table-column prop="createdAt" label="创建时间" width="150"></el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="150">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
                    <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'ActivityDrawLotsList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '', // 搜索字段
            url: '/activityDrawLots/all', // 列表接口
            downloading: false,
            createdAt: ''
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return { search: this.search, query: { del: false, createdAt: this.createdAt } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        // 新增
        addRow() {
            this.$router.push({
                path: '/activityDrawLotsEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        // 编辑
        editRow(row) {
            this.$router.push({
                path: '/activityDrawLotsEdit',
                query: {
                    id: row.id
                }
            });
        },
        // 导出
        download() {
            this.downloading = true;
            const params = {
                size: 10000,
                search: this.search,
                query: {
                    createdAt: this.createdAt,
                    projectId: this.$store.state.projectId
                }
            };
            this.$axios
                .post('/activityDrawLots/excel', params, {
                    responseType: 'blob'
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', '申购.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        // 列表 - 删除
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activityDrawLots/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
