<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="160px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item label="项目" prop="projectId">
                        <project-select v-model="formData.projectId"> </project-select>
                    </el-form-item>
                    <el-form-item label="活动名称">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item label="是否导入名单">
                        <el-switch v-model="formData.useImportRoster" @change="importChange"></el-switch>
                    </el-form-item>
                    <el-form-item prop="collectionId" label="需要持有的藏品">
                        <el-form-item prop="collectionId">
                            <collection-search :isEdit="formData.useImportRoster" :isMultiple="true" @select="onSelect" v-model="formData.collectionIdList" :projectId="formData.projectId">
                            </collection-search>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item prop="targetCollectionId" label="可申购的藏品">
                        <el-form-item prop="targetCollectionId" :rules="{ required: true, message: '请选择藏品' }">
                            <collection-search
                                v-model="formData.targetCollectionId"
                                @select="onSelectTarget"
                                :projectId="formData.projectId"
                            >
                            </collection-search>
<!--                            <div class="form_tip">不选择默认可优先购买所有藏品</div>-->
                        </el-form-item>
                    </el-form-item>
                    <el-form-item prop="activeStartTime" label="申购开始时间" :rules="{ required: true, message: '请选择活动开始时间' }">
                        <el-date-picker v-model="formData.activeStartTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="activeEndTime" label="申购结束时间" :rules="{ required: true, message: '请选择活动结束时间' }">
                        <el-date-picker v-model="formData.activeEndTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item prop="lottyTime" label="抽签/开奖时间" :rules="{ required: true, message: '请选择抽签时间' }">
                        <el-date-picker v-model="formData.lottyTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="finishShowTime"
                                  label="活动结束公示时间">
                        <el-date-picker v-model="formData.finishShowTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="activeTicketNum" label="活动可申购数" :rules="{ required: true, message: '请输入活动可申购数' }">
                        <el-input-number v-model="formData.activeTicketNum" type="number"></el-input-number>
                    </el-form-item>

                    <el-form-item prop="applicationLottyTicketNum" label="总抽签数（展示用）" :rules="{ required: true, message: '请输入总签数（展示用）' }">
                        <el-input-number v-model="formData.applicationLottyTicketNum" type="number"></el-input-number>
                        <div class="form_tip">总抽签数不可大于活动总签数</div>
                    </el-form-item>
                    <el-form-item prop="lottyTicketNum" label="总参与抽签数/实际签数" :rules="{ required: true, message: '请输入总参与抽签数/实际签数' }">
                        <el-input-number v-model="formData.lottyTicketNum" type="number"></el-input-number>
                        <div class="form_tip">总参与抽签数不可大于总抽签数</div>
                    </el-form-item>
                    <el-form-item prop="participateNum" label="已参与人数" v-if="$route.query.id">
                        <el-input-number v-model.number="formData.participateNum" type="number"></el-input-number>
                        <div class="form_tip">请不要随便修改该数值</div>
                    </el-form-item>

                    <el-form-item label="活动是否有效" prop="activeStatus" :rules="{ required: true, message: '请选择活动状态' }">
                        <el-select v-model="formData.activeStatus">
                            <el-option :value="0" label="删除"></el-option>
                            <el-option :value="1" label="有效"></el-option>
                            <el-option :value="9" label="已开奖"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="抽奖码生成格式配置">
                        <el-input v-model="formData.lottyGenConf"></el-input>
                        <div class="form_tip">不填默认配置为#num|6 格式如：#num|6：生成6位数字 #str|2：生成2位字符 #str|2&#num|4：生成2位字符和4位数字</div>
                    </el-form-item>

                    <el-form-item prop="stackable" label="可申购次数是否可叠加">
                        <el-switch
                            v-model="formData.stackable"
                            active-text="是"
                            inactive-text="否"
                        ></el-switch>
                    </el-form-item>

                    <el-form-item prop="advancePurchaseTime" label="时间">
                        <el-input-number v-model="formData.advancePurchaseTime" type="number"> </el-input-number>
                        <div class="form_tip">提前购买时间（小时），示例：1小时 = 1 24小时 = 24；暂不支持小数</div>
                    </el-form-item>

                    <el-form-item prop="advancePurchaseCount" label="次数">
                        <el-input-number v-model="formData.advancePurchaseCount" type="number"></el-input-number>
                        <div class="form_tip">可提前购买次数</div>
                    </el-form-item>

                    <el-form-item label="优先购凭证类型" prop="itemId">
                        <el-select v-model="formData.itemId">
                            <!-- <el-option :value="0" label="永久性优先购凭证"></el-option> -->
                            <el-option :value="1" label="消耗性全局优先购凭证"></el-option>
                            <el-option :value="2" label="消耗性指定藏品优先购凭证"></el-option>
                        </el-select>
                        <div class="form_tip">抽取的优先购凭证类型</div>
                    </el-form-item>

                    <el-form-item prop="text" label="中签人信息">
                        <el-input v-model="formData.winners" type="textarea" rows="5"></el-input>
                    </el-form-item>

                    <el-form-item prop="text" label="说明">
                        <el-input v-model="formData.text" type="textarea" rows="5"></el-input>
                    </el-form-item>

                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import RichText from '../components/RichText.vue';
import SingleUpload from '../components/SingleUpload.vue';
export default {
    components: { RichText, SingleUpload },
    name: 'ActivityDrawLotsEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('/activityDrawLots/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {
                projectId: this.$store.state.projectId,
                useImportRoster: false,
            },
            rules: {
                targetCollectionId: [
                    {
                        required: true,
                        message: '请输入藏品ID',
                        trigger: 'blur'
                    }
                ],
                itemId: [
                    {
                        required: true,
                        message: '请选择凭证类型',
                        trigger: 'blur'
                    }
                ],
                advancePurchaseTime: [
                    {
                        required: true,
                        message: '请输入提前购买时间（小时）',
                        trigger: 'blur'
                    }
                ],
                advancePurchaseCount: [
                    {
                        required: true,
                        message: '请输入可提前购买次数',
                        trigger: 'blur'
                    }
                ],
                projectId: [{ required: true, message: '请选择项目' }]
            }
        };
    },

    computed: {
        canEdit() {
            return !!!this.$route.query.id;
        }
    },
    methods: {
        importChange(e) {
            if(e) {
                this.formData.collectionIdList = []
            }
        },
        onSelect(e) {
            console.log(e);
            if (e) {
                this.$set(this.formData, 'collectionName', e.name);
            }
        },
        onSelectTarget(e) {
            console.log(e);
            if (e) {
                this.$set(this.formData, 'targetCollectionName', e.name);
            }
        },
        // 保存验证
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        // 新增 / 编辑
        submit() {
            let data = { ...this.formData };
            let startTime = new Date(data.activeStartTime).getTime()
            let endTime = new Date(data.activeEndTime).getTime()
            let lottyTime = new Date(data.lottyTime).getTime()
            if(startTime >= endTime) {
                this.$message.error('活动开始时间必须小于活动结束时间');
                return false
            }
            if(startTime >= lottyTime) {
                this.$message.error('活动开始时间必须小于抽签开始时间');
                return false
            }
            if(lottyTime <= endTime) {
                this.$message.error('结束时间必须小于抽签时间');
                return false
            }
            if(data.activeTicketNum < data.applicationLottyTicketNum) {
                this.$message.error('总签数展示用不能大于可申购数');
                return false
            }
            if(data.applicationLottyTicketNum < data.lottyTicketNum) {
                this.$message.error('实际签数不能大于总签数');
                return false
            }
            this.saving = true;
            this.$http
                .post('/activityDrawLots/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activityDrawLots/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
