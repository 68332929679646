<template>
    <div class="list-view">
        <page-title>
            <el-button
                @click="addRow"
                type="primary"
                icon="el-icon-plus"
                :disabled="fetchingData || downloading"
                class="filter-item"
            >
                新增
            </el-button>
            <el-upload
                style="margin-left: 10px; margin-right: 10px"
                class="filter-item"
                ref="upload"
                accept=".xlsx"
                action=""
                :http-request="importExcel"
                :auto-upload="true"
                :show-file-list="false">
                <el-button size="small" class="filter-item" type="primary">导入</el-button>
            </el-upload>
            <el-button
                @click="download(false)"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                导出
            </el-button>
            <el-button
                @click="download(true)"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                下载模板
            </el-button>
        </page-title>
        <div class="filters-container">
            <el-input
                placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
            v-loading="fetchingData"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection"> </el-table-column>
            <el-table-column prop="activeId" label="活动ID"> </el-table-column>
            <el-table-column prop="name" label="活动名称"> </el-table-column>
            <el-table-column prop="phone" label="可申购人手机号"> </el-table-column>
            <el-table-column prop="number" label="可申购数量"> </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="150">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
                    <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
        <el-dialog :title="isUpdate ? '编辑':'新增'" :visible.sync="addOrUpdateShow">
            <el-form :model="formData" :rules="rules" ref="ruleForm">
                <el-form-item label="活动" prop="activeId">
                    <el-select v-model="formData.activeId">
                        <el-option v-for="item in activeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="可申购人手机号" prop="phone">
                    <el-input v-model="formData.phone"></el-input>
                </el-form-item>
                <el-form-item label="可申购数量" prop="number">
                    <el-input-number v-model="formData.number"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="()=> addOrUpdateShow = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'ActivityDrawLotsList',
    mixins: [pageableTable],
    data() {
        return {
            rules: {
                activeId: [
                    { required: true, message: '请选择活动', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入可申购人手机号', trigger: 'blur' }
                ],

            },
            formData: {
                activeId: '',
                name: '',
                phone: '',
                number: 0
            },
            isUpdate: false,
            addOrUpdateShow: false,
            fileList: [],
            multipleMode: false,
            search: '', // 搜索字段
            url: '/activityDrawLotsRoster/all', // 列表接口
            downloading: false,
            createdAt: '',
            activeList: [],
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        submit() {
            this.activeList.map(item=>{
                if(item.id == this.formData.activeId) {
                    this.formData.name = item.name
                }
            })
            this.$http
                .post('/activityDrawLotsRoster/save', this.formData, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.getData()
                    this.addOrUpdateShow = false
                })
                .catch(e => {
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        importExcel(params) {
            let fileObj = params.file; // 相当于input里取得的files
            let fd = new FormData(); // FormData 对象
            fd.append("file", fileObj); // 文件对象

            let url = this.loadUrl
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            this.$axios.post('/activityDrawLotsRoster/import', fd, config).then(res=>{
                if(res) {
                    this.getData()
                }
            })
        },
        beforeGetData() {
            return { search: this.search, query: { del: false, createdAt: this.createdAt } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        // 新增
        addRow() {
            this.formData = {
                activeId: '',
                phone: '',
                number: 0
            }
            this.addOrUpdateShow = true
        },
        // 编辑
        editRow(row) {
            this.formData = row
            this.isUpdate = true
            this.addOrUpdateShow = true
        },
        // 导出
        download(model) {
            this.downloading = true;
            if(model) {
                this.$axios
                    .post('/activityDrawLotsRoster/downloadTemplate ', params, {
                        responseType: 'blob'
                    })
                    .then(res => {
                        console.log(res);
                        this.downloading = false;
                        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', '申购.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    })
                    .catch(e => {
                        console.log(e);
                        this.downloading = false;
                        this.$message.error(e.error);
                    });
                return
            }
            const params = {
                size: 10000,
                search: this.search,
                query: {
                    createdAt: this.createdAt,
                    projectId: this.$store.state.projectId
                }
            };
            this.$axios
                .post('/activityDrawLotsRoster/excel', params, {
                    responseType: 'blob'
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', '申购.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        // 列表 - 删除
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activityDrawLotsRoster/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    },
    created() {
        let query = { del: false, projectId: "0",createdAt: "" }
        this.$http.post('/activityDrawLots/all', {
            page: 0,
            size: 999,
            sort: 'createdAt,desc',
            query
        }, {
            body: 'json'
        }).then(res=>{
            this.activeList = res.content
        })
    }
};
</script>
<style lang="less" scoped></style>
